<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Payment"/>

    <div class="mainBox mainBox65 border shadow-sm mb-0">
        
        <div class="row mb-2">
            <div class="col-12 mb-2">
                <div class="row">
                    <div class="col-12">Payment method</div>
                    <div class="col-6 col-lg-2">
                        <input type="radio" class="form-check-input" v-model="paymentMethod" value="online"> Online
                    </div>
                    <div class="col-6 col-lg-10">
                        <input type="radio" class="form-check-input" v-model="paymentMethod" value="offline"> Offline
                    </div>
                </div>
            </div>

            <div class="col-12"><hr class="mt-1 mb-2" /></div>

            <div class="col-lg-12">
                <span v-if="paymentOpt === 'payBill'" class="fw-bold mb-2">Pay bill</span>
            </div>
        </div>

        <div v-if="paymentMethod === 'offline'" class="row">
            <div class="col-12 mb-3">
                <div>Upload your payment receipt then enter your payment date and amount as per receipt</div>
            </div>
            <div class="col-12 col-lg-6 mb-2">
                <file-pond
                    name="file"
                    class="receipt" 
                    ref="pondR"
                    label-idle="Drop files" 
                    v-bind:allow-multiple="false" 
                    accepted-file-types="image/jpeg, image/png, image/gif, application/pdf" 
                    :server="receiptConfig" 
                    :files="myReceipts" 
                    storeAsFile="true" 
                    @init="handleReceiptPondInit" 
                    @error="uplReceiptError"
                    @processfile="onprocessReceipt"
                    allowRevert="true"
                    allowRemove="false" 
                />
                <div class="text-center small text-grey">Max file size 1MB <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG, GIF, PDF only</div>
            </div>

            <div class="col-12 col-lg-6 mb-2">
                <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="text-grey" style="font-size: 12px; font-weight: 400; line-height: 1.5; padding-bottom: 2px;">Payment date</div>
                        <VueDatePicker v-model="paymentDate" model-type="yyyy-MM-dd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                    </div>

                    <div class="col-lg-6 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" v-model="paymentAmt" id="paymentAmt" placeholder="Amount">
                            <label for="paymentAmt">Amount</label>
                        </div>
                    </div>
                </div><!-- row -->
            </div>

            <div class="col-12 mb-2 text-center">
                <button class="btn btn-secondary me-2" @click="$router.push({ path: urlBack})">
                    <fa icon="angle-left" class="me-2" />Back
                </button>

                <button class="btn btn-secondary">
                    <fa icon="paper-plane" class="me-2" />Submit
                </button>
            </div>
        </div><!-- offline end -->

        <!-- online -->
        <div v-else class="row">
            <div class="col-12">
                <table class="table table-borderless table-hover table-sm" border="0">
                    <tbody>
                        <tr>
                            <td width="20%">Pay to</td>
                            <td width="80%">SignOn.my</td>
                        </tr>
                        <tr>
                            <td>Date</td>
                            <td> {{func.convDateTimeFormat(new Date(), 'datetime')}}</td>
                        </tr>
                        <tr>
                            <td>Amount</td>
                            <td>MYR {{paymentAmt}}</td>
                        </tr>
                        <tr>
                            <td>Remark</td>
                            <td>Pay bill</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-12 text-center">
                <button class="btn btn-secondary me-2" @click="$router.push({ path: urlBack})">
                    <fa icon="angle-left" class="me-2" />Back
                </button>

                <button class="btn btn-secondary">
                    <fa icon="angle-right" class="me-2" />Next
                </button>
            </div>
        </div>
        <!-- online end -->

    </div>
</template>

<script>
import { ref, inject, onMounted, computed, watch, resolveDirective } from 'vue'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

// Image Uploader for avatar
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)
export default {
    name: 'Payment',
    components: {
        TopNavigation, Alert, Popper, FilePond, VueDatePicker
    },
    setup () {
        const alert = ref([])
        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()
        const { getUserId } = useStore()

        const paymentMethod = ref('offline')
        const paymentOpt = ref(null)
        const paymentAmt = ref(0)
        const paymentDate = ref(null) // YYYY-MM-DD
        const urlBack = ref(0)

        const receiptConfig = ref({ 
            "url": axios.defaults.baseURL + "user/xxxx/upload", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            }
        }) 

        const uplReceiptError = (error) => {
            func.addLog('payment', 'uplReceiptError', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

        }

        const onprocessReceipt = (err, file) => {
            if (err === null) {
                pondR.value.removeFile(file) // Remove preview image after uploaded complete
            }

        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        onMounted(() => {
            paymentDate.value = func.convDateTimeFormat(new Date(), 'date')
            paymentOpt.value = route.query.opt
            urlBack.value = route.query.fr

            if (paymentOpt.value === 'payBill') {
                paymentAmt.value = route.query.amt
            }
        })

        return {
            route, router, alert, func, closeAlert, getUserId,
            paymentMethod, paymentOpt, paymentAmt, paymentDate, urlBack, 
            receiptConfig, uplReceiptError, onprocessReceipt
        }

    },
    data () {
        return {
            myReceipts: []
        }
    },
     mounted () {
        const pondR = document.querySelector('.receipt')
        pondR.addEventListener('FilePond:addfile', (e) => {

            if (e.detail.error === null) {
                // console.info('old mounted:  no error')
                this.$refs.pondR.removeFile(e.detail.file) 

            }

        })
     },
     methods: {
        handleSignaturePondInit (){
            this.$refs.pondR.getFiles()

        }
    }
}
</script>

<style>

</style>